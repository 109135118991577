import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import { sample } from 'lodash'
import { correctSpellingMap } from 're-client/utils/progress-tools'
import config from 're-client/config/environment'
import { capitalize } from '@ember/string'
import formatDueDate from 're-client/utils/format-due-date'
import dateFromNow from 're-client/utils/date-from-now'
import { debugAction, debugValue } from 're-client/utils/debug'
import { getMonth } from 're-client/utils/format-date'
import type StudentProgressService from 're-client/services/student-progress'
import type AssignmentsService from 're-client/services/assignments'
import type UserService from 're-client/services/user'
import type RouterService from '@ember/routing/router-service'
import type LogoutService from 're-client/services/logout'
import type { FeatureService } from '@blakeelearning/features'
import type ThemeService from 're-client/services/theme'
import type DeviceNetwork from '@blakeelearning/device/device/network/service'
import type OfflineScreenService from 're-client/services/offline-screen'
import type QuestService from 're-client/services/quest'
import type EssentialQuestService from 're-client/services/essential-quest'
import type DebugModeService from 're-client/services/debug-mode'

const themeOptions = config.themesByMonth.map((theme, index) => ({
  value: index.toFixed(),
  label: `${getMonth(index)} - ${theme}`,
}))

export default class IndexController extends Controller {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare assignments: AssignmentsService

  @service
  declare user: UserService

  @service
  declare router: RouterService

  @service
  declare logout: LogoutService

  @service
  declare features: FeatureService

  @service
  declare theme: ThemeService

  @service('device/network')
  declare network: DeviceNetwork

  @service
  declare offlineScreen: OfflineScreenService

  @service
  declare quest: QuestService

  @service
  declare essentialQuest: EssentialQuestService

  @service
  declare debugMode: DebugModeService

  get student() {
    return this.user.student
  }

  get showPlacementTestText() {
    return this.studentProgress.showLessonsPlacementTest
  }

  get eggsAsArray() {
    return String(this.student.eggs).split('').map(Number)
  }

  get bannerSvgName() {
    return this.theme.bannerName
  }

  get currentLessonTitle() {
    const currentLesson = this.studentProgress.lessonsCurrentLesson
    if (currentLesson === 'quiz') {
      return 'Quiz'
    }
    return currentLesson
  }

  get hasFinishedLessons() {
    return this.studentProgress.hasFinishedLessons
  }

  get gamesLocked() {
    return this.studentProgress.isAreaLocked('games')
  }

  get awardsLocked() {
    return this.studentProgress.isAreaLocked('awards')
  }

  get drivingTestsLocked() {
    return this.studentProgress.isAreaLocked('drivingTests')
  }

  get houseLocked() {
    return this.studentProgress.isAreaLocked('house')
  }

  get puzzlesLocked() {
    return this.studentProgress.isAreaLocked('puzzles')
  }

  get shopLocked() {
    return this.studentProgress.isAreaLocked('shop')
  }

  get spellingLocked() {
    return this.studentProgress.isAreaLocked('spelling')
  }

  get storyFactoryLocked() {
    return this.studentProgress.isAreaLocked('storyFactory')
  }

  get storylandsLocked() {
    return this.studentProgress.isAreaLocked('storylands')
  }

  get storylandsIcon() {
    return this.storylandsLocked ? 'storylands-locked-egg' : 'StoryLands'
  }

  get spellingIcon() {
    return this.spellingLocked ? 'spelling-locked-egg' : 'Spelling'
  }

  get drivingTestsIcon() {
    return this.drivingTestsLocked ? 'drivingtests-locked-egg' : 'DrivingTests'
  }

  get storyFactoryIcon() {
    return this.storyFactoryLocked ? 'storyfactory-locked-egg' : 'StoryFactory'
  }

  get greetings() {
    return ['Hello,', 'Hi,', 'Welcome,']
  }

  get myLessonsTitle() {
    if (this.hasFinishedLessons) {
      return 'Complete!'
    }
    return 'My Lessons'
  }

  get welcomeGreeting() {
    return sample(this.greetings)
  }

  get isAuLocale() {
    const studentLocale = this.user.accent.toLowerCase()
    return studentLocale === 'au'
  }

  get showMyReadingGoals() {
    return this.features.isEnabled('my_reading_goals')
  }

  get task() {
    return this.assignments.currentTask
  }

  get hasTask() {
    return this.assignments.hasTask
  }

  get assignmentThumbnailUrl() {
    return this.task?.thumbnailUrl
  }

  get assignmentTitle() {
    switch (this.task?.__typename) {
      case 'AssignmentTaskLesson':
        return `Lesson ${this.task.lessonId}`
      case 'AssignmentTaskSpelling':
        return `Lesson`
      case 'AssignmentTaskClinkerCastle':
        return `Lesson ${this.task.lessonId}`
      case 'AssignmentTaskDrivingTests':
        return `${this.task.category.split('_').map(capitalize).join(' ')}: Lesson ${this.task.lessonId}`
      default:
        return undefined
    }
  }

  get assignmentName() {
    if (!this.task) return undefined

    if (this.task.__typename === 'AssignmentTaskLesson') {
      const matcher = /Lesson [0-9]* - /g
      return capitalize(this.task.title.replace(matcher, ''))
    }

    return this.task.title
  }

  get assignmentDueDate() {
    if (!this.task) return undefined
    return formatDueDate(new Date(this.task.endAt))
  }

  get assignmentTimeRemaining() {
    if (!this.task) return undefined

    // TODO: The dateFromNow util can be replaced with date-fns intlFormatDistance once we drop support for ios13
    return dateFromNow(new Date(this.task.endAt), Date.now())
  }

  get assignmentInstruction() {
    if (!this.task) return undefined

    const instructionForType = {
      AssignmentTaskLesson:
        'You must complete all the activities in the lesson.',
      AssignmentTaskSpelling:
        'You must complete the quiz at the end of the spelling lesson.',
      AssignmentTaskClinkerCastle: `You must complete the quiz at the end of the storylands lesson.`,
      AssignmentTaskDrivingTests: `You must complete the Driving Test.`,
    }

    return instructionForType[this.task.__typename]
  }

  get hasEssentialQuestGoal() {
    return this.essentialQuest.active
  }

  get questsFeatureEnabled() {
    return this.features.isEnabled('quests')
  }

  @action
  startTask() {
    return this.router.transitionTo(
      // @ts-expect-error router params type no bueno
      ...this.task.targetRouteParams,
    )
  }

  @action
  myProgram() {
    this.router.transitionTo('my-program')
  }

  @action
  storylands() {
    this.router.transitionTo(
      'storylands.map',
      this.studentProgress.storylandsCurrentMap,
    )
  }

  @action
  spelling() {
    this.router.transitionTo(
      'spelling.map',
      correctSpellingMap(this.studentProgress.spellingCurrentMap),
    )
  }

  @action
  myReadingGoals() {
    this.router.transitionTo('my-reading-goals')
  }

  @action
  lessons() {
    this.router.transitionTo(
      'lessons.map',
      this.studentProgress.lessonsCurrentMap,
    )
  }

  @action
  drivingTests() {
    this.router.transitionTo('driving-tests')
  }

  @action
  readingJournal() {
    this.goToReadingJournal()
  }

  @action
  goToReadingJournal(location = window.location) {
    if (this.network.status.isOffline) {
      this.offlineScreen.display()
      return
    }

    // We are navigating to the internal route with the `return_to` query param set to the journal,
    // this will land us at the journal after sso is finished.
    // Internal route is used to ensure that "login_provider" is properly included
    const base = config.APP.apiEndpoint ?? location.origin
    const rexLoginUrl = new URL('rex', base)
    rexLoginUrl.searchParams.set('return_to', '/journal')
    location.assign(rexLoginUrl.toString())
  }

  @action
  storyFactory() {
    this.router.transitionTo('story-factory')
  }

  @action
  house() {
    this.router.transitionTo('house')
  }

  @action
  awards() {
    this.router.transitionTo('awards')
  }

  @action
  games() {
    this.router.transitionTo('games')
  }

  @action
  plaza() {
    this.router.transitionTo('plaza')
  }

  @action
  shop() {
    this.router.transitionTo('shop.department', 'avatar')
  }

  @action
  puzzles() {
    this.router.transitionTo('puzzles')
  }

  @action
  critters() {
    this.router.transitionTo('critters.index')
  }

  @action
  play() {
    this.router.transitionTo('play')
  }

  @action
  songs() {
    this.router.transitionTo('songs')
  }

  @action
  goToChangingRoom() {
    this.router.transitionTo('changing-room')
  }

  @action
  bye() {
    this.logout.doLogout()
  }

  @action
  @debugAction({
    lesson: {
      type: 'select',
      options: generateOptions('lessons', config.APP.lastLessonsLesson),
    },
  })
  async goToLessonsLesson({ lesson }: { lesson: string }) {
    await this.debugMode.completeLessonsLesson(lesson)
    this.router.transitionTo('lessons.lesson', lesson)
  }

  @action
  @debugAction({
    lesson: {
      type: 'select',
      options: generateOptions('spelling', config.APP.lastSpellingLesson),
    },
  })
  async goToSpellingLesson({ lesson }: { lesson: string }) {
    await this.debugMode.completeSpellingLesson(lesson)
    this.router.transitionTo('spelling.lesson', lesson)
  }

  @action
  @debugAction({
    lesson: {
      type: 'select',
      options: generateOptions('storylands', config.APP.lastStorylandsLesson),
    },
  })
  async goToStorylandsLesson({ lesson }: { lesson: string }) {
    await this.debugMode.completeStorylandsLesson(lesson)
    this.router.transitionTo('storylands.lesson', lesson)
  }

  @action
  @debugAction({
    month: {
      type: 'select',
      options: themeOptions,
    },
  })
  setTheme({ month }: { month: string }) {
    this.router.transitionTo('index', { queryParams: { qa_month: month } })
  }

  @debugValue()
  get currentQuest() {
    return this.quest.currentQuest
  }
}

function generateOptions(
  lessonType: 'lessons' | 'spelling' | 'storylands',
  numberOfLessons: number,
) {
  const options = []
  let i = 0

  for (i; i < numberOfLessons; i++) {
    const lessonNumber = i + 1
    const progressInfoForLessons = config.studentProgress.progress
    const { lessonsPerMap } = progressInfoForLessons[lessonType]
    const mapNumber = Math.ceil(lessonNumber / lessonsPerMap)

    options.push({
      value: lessonNumber.toFixed(),
      label: `Lesson ${lessonNumber} - Map ${mapNumber}`,
    })
  }
  return options
}

declare module '@ember/controller' {
  interface Registry {
    index: IndexController
  }
}
