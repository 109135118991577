import Controller from '@ember/controller'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import { CertificateColourEnum } from 're-client/graphql/graphql'
import type StudentOperationsService from '@blakeelearning/student-operations/operations/service'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type { ModelFor } from 're-client/utils/route-model'
import type { FeatureService } from '@blakeelearning/features'
import type StudentProgressService from 're-client/services/student-progress'
import type LessonsMapQuizRoute from 're-client/routes/lessons/map/quiz'
import type CertificateService from 're-client/services/certificate'
import { debugAction } from 're-client/utils/debug'
import type CaperLoaderService from 're-client/services/caper-loader'
import type JesterLoaderService from 're-client/services/jester-loader'
import type ErrorHandlerService from 're-client/services/error-handler'
import type QuestService from 're-client/services/quest'
import type EssentialQuestService from 're-client/services/essential-quest'

type CategoryAreaKey = string
type CategoryAreaResults = Record<'correct' | 'incorrect', string[]>
interface MapQuizResults {
  correctCount: number
  totalCount: number
  correctAnswers: string[]
  incorrectAnswers: Record<string, string>
  categoryResults: null | Record<CategoryAreaKey, CategoryAreaResults>
}

const {
  APP: { product, lastMap },
} = config

interface PendingCertificateResponse {
  map: number
  score_percentage: number
  quiz_result_created_at: string
  colour: CertificateColourEnum
  id: string
}

function generateResult(correctCount: number) {
  const result = {
    correctCount,
    totalCount: 15,
    correctAnswers: [] as string[],
    incorrectAnswers: {} as Record<string, string>,
    categoryResults: null,
  }

  for (let i = 1; i <= result.totalCount; i++) {
    if (i <= correctCount) {
      result.correctAnswers.push(`debug_map_quiz_${i}`)
    } else {
      result.incorrectAnswers[`debug_map_quiz_${i}`] = `wrong_${i}`
    }
  }

  return result
}

/**
 * End-of-map quiz activity, not to be confused with the end-of-lesson
 * quiz activity
 *
 * @class lessonsMapQuizActivity
 */
export default class LessonsMapQuizController extends Controller {
  @service declare certificate: CertificateService

  @service declare operations: StudentOperationsService

  @service declare studentProgress: StudentProgressService

  @service declare user: UserService

  @service declare router: RouterService

  @service declare features: FeatureService

  @service declare caperLoader: CaperLoaderService

  @service declare jesterLoader: JesterLoaderService

  @service declare errorHandler: ErrorHandlerService

  @service declare quest: QuestService

  @service declare essentialQuest: EssentialQuestService

  @tracked showAlert: 'caper' | 'jester' | 'text' | null = null

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  declare model: ModelFor<LessonsMapQuizRoute>

  get currentMap() {
    return this.model.mapId
  }

  get student() {
    return this.user.student
  }

  get showCaperAlert() {
    return this.showAlert === 'caper'
  }

  get showJesterAlert() {
    return this.showAlert === 'jester'
  }

  get showTextAlert() {
    return this.showAlert === 'text'
  }

  get questsFeatureEnabled() {
    return this.features.isEnabled('quests')
  }

  async completeQuiz(results: MapQuizResults) {
    const context = {
      precinct: 'lessons',
      remoteId: this.student.remoteId,
      product,
    }
    const response = await this.operations.completeMapQuiz(
      context,
      this.currentMap,
      this._toSnakeCase(results),
    )

    await this.quest.fetch()
    await this.essentialQuest.fetch()

    if (
      !!response &&
      typeof response === 'object' &&
      'pending_certificate' in response &&
      this._isPendingCertificateResponse(response.pending_certificate)
    ) {
      const {
        pending_certificate: {
          colour,
          map,
          score_percentage: scorePercentage,
          quiz_result_created_at: quizResultCreatedAt,
          id,
        },
      } = response

      this.certificate.updateCache({
        id,
        colour,
        map,
        scorePercentage,
        quizResultCreatedAt,
      })
    }
  }

  @action
  @debugAction({
    results: {
      type: 'select',
      options: [
        { label: '15/15 - Gold Certificate', value: '15' },
        { label: '14/15 - Gold Certificate', value: '14' },
        { label: '13/15 - Silver Certificate', value: '13' },
        { label: '12/15 - Silver Certificate', value: '12' },
        { label: '11/15 - Bronze Certificate', value: '11' },
      ],
      values: {
        15: generateResult(15),
        14: generateResult(14),
        13: generateResult(13),
        12: generateResult(12),
        11: generateResult(11),
      },
    },
  })
  async passQuiz({ results }: { results: MapQuizResults }) {
    await this.completeQuiz(results)
    await this.next()
  }

  @action
  @debugAction()
  async failQuiz() {
    await this.completeQuiz(generateResult(0))
    await this.next()
  }

  /**
   * Saves the quiz results as a map quiz complete event to the backend and checks the response for a pendingCertificate
   * then updates the GetStudent query in the apollo cache.
   */
  @action
  async quizCompleted(results: MapQuizResults) {
    await this.completeQuiz(results)
    this.interactive.callInteractionMethod('nextable')
  }

  /**
   * Transitions to the next place. Which could be:
   * - the certificate route - if they have earned a pending certificate
   * - the finished-re-lessons route - if they have finished all lessons
   * - the next map
   */
  @action
  @debugAction()
  async next() {
    await this.studentProgress.fetchProgress()

    let nextRoute: [string] = ['lessons.map.next']

    if (!this.features.isEnabled('bypass_certificate_save')) {
      const hasPendingCertificate =
        await this.certificate.hasPendingCertificate()
      if (hasPendingCertificate) {
        nextRoute = ['lessons.certificate']
      }
    }
    if (this.studentProgress.lessonsCurrentMap > lastMap) {
      nextRoute = ['lessons.finished-re-lessons']
    }

    this.quest.displayQuestAlertIfRequired(nextRoute)
  }

  /**
   * Adds one to the student's eggs count
   */
  @action
  @debugAction({
    amount: {
      type: 'number',
      value: '1',
    },
  })
  incrementScore(args: { amount: number } | number = 1) {
    let amount

    if (typeof args === 'number') {
      amount = args
    } else {
      amount = args.amount
    }

    this.user.incrementEggs(amount)
  }

  @action
  @debugAction({
    popupExample: {
      type: 'select',
      options: [
        { label: 'Caper Activity', value: 'caper' },
        { label: 'Jester Activity', value: 'jester' },
        { label: 'Text', value: 'text' },
      ],
    },
  })
  showQuestAlert({
    popupExample,
  }: {
    popupExample: 'caper' | 'jester' | 'text'
  }) {
    this.showAlert = popupExample
  }

  @action
  hideQuestAlert() {
    this.showAlert = null
  }

  @action
  onQuestAlertError(error: Error) {
    this.errorHandler.handleContentUnhandledError(error)
  }

  // maths quizzes return as snakecase atm. These should be made consistent
  // across all products
  _toSnakeCase(results: MapQuizResults) {
    return {
      correct_count: results.correctCount,
      total_count: results.totalCount,
      correct_answers: results.correctAnswers,
      incorrect_answers: results.incorrectAnswers,
      category_results: results.categoryResults,
    }
  }

  /**
   * PendingCertificateResponse type guard
   */
  _isPendingCertificateResponse(
    pendingCertificate: unknown,
  ): pendingCertificate is PendingCertificateResponse {
    return (
      !!pendingCertificate &&
      typeof pendingCertificate === 'object' &&
      'id' in pendingCertificate &&
      typeof pendingCertificate.id === 'string' &&
      'map' in pendingCertificate &&
      typeof pendingCertificate.map === 'number' &&
      'score_percentage' in pendingCertificate &&
      typeof pendingCertificate.score_percentage === 'number' &&
      'colour' in pendingCertificate &&
      typeof pendingCertificate.colour === 'string' &&
      Object.values(CertificateColourEnum).includes(
        (pendingCertificate as PendingCertificateResponse).colour,
      ) &&
      'quiz_result_created_at' in pendingCertificate &&
      typeof pendingCertificate.quiz_result_created_at === 'string'
    )
  }
}

declare module '@ember/controller' {
  interface Registry {
    'lessons/map/quiz': LessonsMapQuizController
  }
}
