/**
 * This is a polyfill for Error.cause
 * It can be removed once our all browsers support it natively
 * see https://caniuse.com/mdn-javascript_builtins_error_cause
 */
import 'core-js/stable/error'

/**
 * This is a polyfill for Array.prototype.at
 * It can be removed once our all browsers support it natively
 * https://caniuse.com/mdn-javascript_builtins_array_at
 */
import 'core-js/stable/array/at'

import './deprecation-workflow'

import Application from '@ember/application'
import Resolver from 'ember-resolver'
import loadInitializers from 'ember-load-initializers'
import { startLog, checkIgnore } from '@blakeelearning/log'
import config from 're-client/config/environment'
import isNetworkError from 'is-network-error'

/**
 * This is imported here as an insurance policy against accidentally
 * refactoring away lodash.  Caper apps expect 'lodash' to be available
 * requirejs/loader.js, and will crash if it's missing.  As such, don't remove
 * this unless this situation changes!
 */
import 'lodash'
import { gsap } from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'

gsap.registerPlugin(MotionPathPlugin)

export default class App extends Application {
  modulePrefix = config.modulePrefix

  podModulePrefix = config.podModulePrefix

  Resolver = Resolver
}

startLog({
  rollbar: {
    ...config.rollbar,
    checkIgnore(...args) {
      const possibleErrors = args[1]
      if (possibleErrors.some((possibleError) => isNetworkError(possibleError)))
        return true
      return checkIgnore(...args)
    },
    ignoredMessages: [/^Script '.*' blocked$/, /^cancelled$/],
  },
})

loadInitializers(App, config.modulePrefix)
