import { AbortError, UnauthorizedError } from '@ember-data/adapter/error'
import { isApolloError } from '@apollo/client/core'
import type { ApolloError, ServerError } from '@apollo/client/core'
import { HTTPError } from 'ky'
import { ScriptBlockedError } from '@blakeelearning/content-loader'
import isNetworkError from 'is-network-error'

// See https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
type FetchAPINetworkError = TypeError

// See https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest#xmlhttprequests_being_stopped
type XMLHttpRequestWithZeroStatus = XMLHttpRequest & { status: 0 }

// Not very well documented, but some info is available in https://www.apollographql.com/docs/react/api/link/apollo-link-error/ - note that the `networkError` property can also be a ServerError
type ApolloErrorWithNetworkError = ApolloError & {
  networkError: FetchAPINetworkError
}

export type ErrorRepresentingNetworkError =
  | AbortError
  | FetchAPINetworkError
  | XMLHttpRequestWithZeroStatus
  | ApolloErrorWithNetworkError
  | ScriptBlockedError

/**
 * This function can be used to tell us if an error we are seeing was caused by
 * a failed request.  In general, any such error does not need to be logged to
 * rollbar so long as we can handle it by advising the student to check their
 * connection and try again.
 *
 * We're using the term "network error" here to mean an HTTP request for which
 * the browser has yielded no response.  These are also called abort errors
 * sometimes, but network error is the term used in {@link
 * https://developer.mozilla.org/en-US/docs/Web/API/fetch}.
 */
export function isErrorRepresentingNetworkError(
  error: unknown,
): error is ErrorRepresentingNetworkError {
  if (error instanceof AbortError) return true
  if (error instanceof XMLHttpRequest) return error.status === 0
  if (error instanceof Error && isApolloError(error))
    return isNetworkError(error.networkError)
  if (error instanceof ScriptBlockedError) return true
  return isNetworkError(error)
}

type ResponseWith401Status = Response & { status: 401 }
type HTTPErrorWith401Response = HTTPError & { response: ResponseWith401Status }

type XMLHttpRequestWith401Status = XMLHttpRequest & { status: 401 }
type ServerErrorWith401StatusCode = ServerError & { statusCode: 401 }
type ApolloErrorWith401StatusCode = ApolloError & {
  networkError: ServerErrorWith401StatusCode
}

export type AuthorizationError =
  | UnauthorizedError
  | HTTPErrorWith401Response
  | XMLHttpRequestWith401Status
  | ApolloErrorWith401StatusCode

export function isAuthorizationError(
  error: unknown,
): error is AuthorizationError {
  // check if the current error is an abort error according to Ember
  if (error instanceof UnauthorizedError) return true
  if (error instanceof HTTPError) return error.response.status === 401
  if (error instanceof XMLHttpRequest) return error.status === 401
  if (error instanceof Error && isApolloError(error))
    return (
      error.networkError !== null &&
      'statusCode' in error.networkError &&
      error.networkError.statusCode === 401
    )

  return false
}

export interface ErrorLike {
  name: string
  message: string
}

class ContentError extends Error {
  constructor(name: string, error: unknown) {
    let message: string
    let cause: ErrorLike | undefined

    if (isErrorLike(error)) {
      message = error.message
      cause = error
    } else if (typeof error === 'string') {
      message = error
    } else {
      message = `Unrecognised error: ${String(error)}`
    }

    super(message, { cause })
    this.name = name
  }
}

export function isErrorLike(error: unknown): error is ErrorLike {
  return (
    typeof error === 'object' &&
    error !== null &&
    'name' in error &&
    'message' in error &&
    typeof error.name === 'string' &&
    typeof error.message === 'string'
  )
}

export function isActionableError(error: unknown): error is ErrorLike {
  if (error instanceof ContentError && isErrorLike(error.cause)) {
    return isActionableError(error.cause)
  }
  return !isErrorRepresentingNetworkError(error) && !isAuthorizationError(error)
}

/**
 * @see {@link https://www.npmjs.com/package/errlop}
 * @example
 * try {
 *   doSomething()
 * } catch (error) {
 *   throw contentError('MyErrorName', error)
 * }
 *
 * @example
 * throw contentError('MyErrorName', 'my message')
 */
export function contentError(name: string, error: unknown): unknown {
  return new ContentError(name, error)
}

function hasStatusCode(obj: unknown): obj is { statusCode: number } {
  return (
    !!obj &&
    typeof obj === 'object' &&
    obj.hasOwnProperty('statusCode') &&
    typeof (obj as { statusCode: unknown }).statusCode === 'number'
  )
}

/**
 * This is used to decide if a "network error" (Apollo term) can be retried.
 * We follow the ky logic for this, which says that any failed request (also
 * called a "network error", but meaning a fetch request that didn't resolve),
 * as well as certain 4xx and 5xx server errors can be.
 */
export function isApolloNetworkErrorRetryable(error: unknown): boolean {
  return (
    isNetworkError(error) ||
    (hasStatusCode(error) &&
      [408, 413, 429, 500, 502, 503, 504].includes(error.statusCode))
  )
}
