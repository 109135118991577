import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import type LoadingUiService from 're-client/services/loading-ui'
import type ErrorHandlerService from 're-client/services/error-handler'
import type { ContentSpec } from '@blakeelearning/messenger'
import type ReadingGoalsService from 're-client/services/reading-goals'
import type { QuizAnswerInput } from 're-client/graphql/graphql'
import { v4 as uuidV4 } from 'uuid'
import {
  contentError,
  isErrorRepresentingNetworkError,
} from 're-client/utils/errors'
import type RouterService from '@ember/routing/router-service'

interface CreateQuizResultInput {
  quizId: string
  answers: QuizAnswerInput[]
}

interface CreateQuizResultOutput {
  eggsReward: number
  passed: boolean
}
export default class MyReadingGoalsQuizController extends Controller {
  @service
  declare errorHandler: ErrorHandlerService

  @service
  declare loadingUi: LoadingUiService

  @service
  declare readingGoals: ReadingGoalsService

  @service
  declare router: RouterService

  get contentSpec(): ContentSpec {
    return {
      readyForUserInput: {
        type: 'message',
      },
      unhandledError: {
        type: 'message',
      },
      goHome: {
        type: 'message',
      },
      goBack: {
        type: 'message',
      },
      createQuizResult: {
        type: 'message',
      },
    }
  }

  @action
  async createQuizResult({
    quizId,
    answers,
  }: CreateQuizResultInput): Promise<CreateQuizResultOutput> {
    try {
      const result = await this.readingGoals.createQuizResult({
        quizId,
        answers,
        attemptUuid: uuidV4(),
      })

      if (result?.readingGoalsQuizResultCreate) {
        const { passed, eggsReward } =
          result.readingGoalsQuizResultCreate.readingGoalsQuizResult

        return { passed, eggsReward: eggsReward ?? 0 }
      }

      return { passed: false, eggsReward: 0 }
    } catch (error) {
      if (isErrorRepresentingNetworkError(error))
        throw contentError('NetworkError', error)
      throw contentError('SaveFailed', error)
    }
  }

  @action
  goHome() {
    void this.router.transitionTo('/')
  }

  @action
  goBack() {
    void this.router.transitionTo('my-reading-goals.index')
  }

  @action
  unhandledError({ error }: { error: { name?: string; message?: string } }) {
    this.errorHandler.handleContentUnhandledError(error)
  }

  @action
  readyForUserInput() {
    this.loadingUi.hide()
  }
}
